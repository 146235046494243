import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import GroupSummary from './views/GroupSummary.vue'
import GroupRank from './views/GroupRank.vue'
import UserData from './views/UserData.vue'
import AddCompetition from '././views/AddCompetition.vue'
import CompetitionTextList from './views/CompetitionTextList.vue'
import BattlePowerRank from './views/BattlePowerRank.vue'
import GroupScoreRank from './views/GroupScoreRank.vue'
import BattleNumRank from './views/BattleNumRank.vue'

import store from './store'
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { redirectAlreadyLogin: true }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login,
      meta: { redirectAlreadyLogin: true }
    },
    {
      path: '/groupSummary',
      name: 'groupSummary',
      component: GroupSummary,
    },
    {
      path: '/groupRank',
      name: 'groupRank',
      component: GroupRank,
    },
    {
      path: '/userData',
      name: 'userData',
      component: UserData,
    },
    {
      path: '/addCompetition/:groupNumber/:textId?',
      name: 'addCompetition',
      component: AddCompetition,
    },
    {
      path: '/competitionTextList/:groupNumber?/:selectType?/:currentPage?',
      name: 'competitionTextList',
      component: CompetitionTextList,
    },
	{
	  path: '/battlePowerRank',
	  name: 'battlePowerRank',
	  component: BattlePowerRank,
	},
	{
	  path: '/groupScoreRank',
	  name: 'groupScoreRank',
	  component: GroupScoreRank,
	},
	{
	  path: '/battleNumRank',
	  name: 'battleNumRank',
	  component: BattleNumRank,
	}
  ]
})

router.beforeEach((to, from, next) => {
  const { user, token } = store.state
  const { requiredLogin, redirectAlreadyLogin } = to.meta
  if (token) {
    axios.defaults.headers.common["token"] = token
  }

  if (to.name === 'logout') {
    store.commit('logout')
    next('login')
  }

  if (!user.isLogin) {
      if (requiredLogin) {
        next('login')
      } else {
        next()
      }
  } else {
    if (redirectAlreadyLogin) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
