<template>
  <a-form class="login_form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-item label="用户名" class="login_form_item">
      <a-input v-model:value="form.username" placeholder="请输入极速中文网用户名"/>
    </a-form-item>
 
    <a-form-item label="密码" class="login_form_item">
      <a-input v-model:value="form.password" type="password" placeholder="请输入密码"/>
    </a-form-item>
	
	<a-form-item label="绑定码" v-if="form.showBindTokenForm" class="login_form_item">
	  <a-input v-model:value="form.bindToken"/>
	</a-form-item>

    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="onSubmit">
        登录
      </a-button>
      <a-button style="margin-left: 10px;" @click="register">
        注册
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, reactive, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ResponseType } from '../store'
import axios from 'axios'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'Login',
  components: {

  },
  setup() {
    const router = useRouter()
    const reactiveData = reactive({
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username:'',
        password:'',
		bindToken:'',
		showBindTokenForm:false,
      },
    })

    const store = useStore()

    const onSubmit = () => {
      const payload = {
        username:reactiveData.form.username,
        password:reactiveData.form.password,
      }
	  reactiveData.showBindTokenForm = false
      store.dispatch('login', payload).then((data) => {
          const result = data
		  if(data.status == 2){
			reactiveData.form.bindToken = result.data.bindToken
			reactiveData.form.showBindTokenForm = true
		  }
          message.info(result.msg)
          if(result.status == 1){
            setTimeout(() => {
              router.push('/')
            }, 1000)
          }
      })
    }
	
	const register = () => {
		const link = 'https://www.jsxiaoshi.com'
		window.open(link, '_blank');
	}
  
    return {
       ...toRefs(reactiveData),
      onSubmit,
	  register
    }
  }
})

</script>
<style scoped>
  .login_form{
    margin-top:50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .login_form_item{
    width:350px;
  }
</style>