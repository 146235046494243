
import { defineComponent, reactive, onMounted, toRefs, ref, watch } from 'vue'
import { SelectTypes } from 'ant-design-vue/es/select'
import { useStore } from 'vuex'
import { useRoute,useRouter } from 'vue-router'
import { ResponseType } from '../store'
import { getNowDate } from '../helper'
import { message } from 'ant-design-vue'
import axios from 'axios'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '群号',
    dataIndex: 'group_number',
  },
  {
    title: '文本标题',
    dataIndex: 'title',
  },
  {
    title: '文本字数',
    dataIndex: 'word_number',
  },
  {
    title: '段号',
    dataIndex: 'segment_number',
  },
  {
    title: '上传者',
    dataIndex: 'user_group_name',
  },
  {
    title: '开始时间',
    dataIndex: 'begin_time',
  },
  {
    title: '结束时间',
    dataIndex: 'end_time',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
  },
  {
    title: '操作',
    dataIndex: 'operation',
	slots: { customRender: 'operation' },
  }
]

export interface GroupProps {
  group_number: string;
  group_name: string;
}

export default defineComponent({
  name: 'Home',
  components: {

  },
  setup() {
	const route = useRoute()
    const router = useRouter()
	const groupNumber = route.params.groupNumber || ''
	let currentPage = route.params.currentPage || 1
	currentPage = parseInt(currentPage.toString())
	
    const reactiveData = reactive({
      data: [],
      pagination: {
        pageSize:30,
        total:0,
        current: currentPage,
      },
      loading: false,
      columns,
      groupNumber:groupNumber,
      groupList:[] as any[],
	  selectTypeList:  [
		{
			value: "0",
			label: '全部'
		}, 
		{
			value: "1",
			label: '未结束'
		}, 
		{
			value: "2",
			label: '已结束'
	  }],
	  selectType: route.params.selectType || "1",
      textInfo:{
        wordNumber:0,
        content:'',
      },
	  seniorVersionEndTime:'',
    })

    const store = useStore()

    const fetch = (params = {}) => {
      reactiveData.loading = true
	  let beginTime = ''
	  let endTime = ''
      const day = new Date()
      if(reactiveData.selectType === "1"){
      	day.setTime(day.getTime())
      	beginTime = day.getFullYear()+"-" + (day.getMonth()+1) + "-" + day.getDate() + ' 00:00:00'
      }else if(reactiveData.selectType === "2"){
      	day.setTime(day.getTime() - 24*60*60*1000)
      	endTime = day.getFullYear()+"-" + (day.getMonth()+1) + "-" + day.getDate() + ' 00:00:00'
      }
	  const payload = {
          groupNumber:reactiveData.groupNumber,
          userToken:localStorage.getItem('token'),
		  sort:'asc',
		  beginTime:beginTime,
		  endTime:endTime,
          ...params
      }

      store.dispatch('getCompetitionTextList', payload).then((data: ResponseType<any>) => {
          const result = data.data.data
          const pagination = { ...reactiveData.pagination }
          pagination.total = result.totalCount
          reactiveData.loading = false
          reactiveData.data = result.list
          reactiveData.pagination = pagination
      })
    }

    const getGroupList = (params = {}) => {
      const payload = {
		  type:1,
		  userToken:localStorage.getItem('token')
	  }
      store.dispatch('getGroupList', payload).then((data) => {
          const result = data.data.data
          const groupLists: any = [{
			'value': '',
			'label':'请选择群',
			'isSeniorVersion': 0,
			'seniorVersionEndTime': ''
			}
		  ]
          result.forEach((val: any, idx: any, array: any) => {
              if(val.group_number > 1000){
                  groupLists.push({
                       'value':val.group_number,
                       'label':val.group_name,
					   'isSeniorVersion':val.is_senior_version,
					   'seniorVersionEndTime':val.senior_version_end_time
                  })
              }
          })
          reactiveData.groupList = groupLists
      })
    }
	
	const doSearch = () => {
	  //初始化页数
	  reactiveData.pagination.current = 1
	  
	  search()
	}

    const search = () => {
	  if(reactiveData.groupNumber == ''){
		   message.info('请先选择群！')
		   return
	  }
	  
	  updateRouter()
		
	  fetch({
	    pageSize: reactiveData.pagination.pageSize,
	    pos: (reactiveData.pagination.current - 1) * reactiveData.pagination.pageSize,
	  })
    }
	
	const updateRouter = () => {
		router.push('/competitionTextList/' + reactiveData.groupNumber + '/' + reactiveData.selectType + '/' + reactiveData.pagination.current)
	}
	
    const addCompetition = () => {
      router.push({
		   name: 'addCompetition',
		   params: {
		      groupNumber:reactiveData.groupNumber,
		   }
	  })
    }
	
	const deleteCompetition = (id:number) => {
	  const payload = {
	  	id:id,
	  	userToken:localStorage.getItem('token')
	  }
	  store.dispatch('deleteCompetitionText', payload).then((data) => {
		  const result = data.data
	      if(result.status == 1){
			  message.success(result.msg)
			  setTimeout(function(){
				window.location.reload()
			  },200)
		  }else{
			  message.error(result.msg)
		  }
	  })
	}

    onMounted(() => {

      getGroupList()

	  if(reactiveData.groupNumber != ''){
		  setTimeout(function(){
			search()
		  },1000)
	  }
    })

    const handleTableChange = (pagination: any) => {
      const pager = { ...reactiveData.pagination }
      pager.current = pagination.current
      reactiveData.pagination = pager
	  updateRouter()
      fetch({
        pageSize: pagination.pageSize,
        pos: (pagination.current - 1) * pagination.pageSize,
      })
    }

    const focus = () => {
      console.log('focus')
    }
    
    const handleChange = (value: any) => {
		reactiveData.seniorVersionEndTime = ''
		reactiveData.groupList.forEach((val: any, idx: any, array: any) => {
			if(val.value == value){
				if(val.isSeniorVersion == 1){
					reactiveData.seniorVersionEndTime = val.seniorVersionEndTime
				}else{
					reactiveData.seniorVersionEndTime = '';
				}
		    }
		})
    }
  
    return {
       ...toRefs(reactiveData),
       handleTableChange,
       focus,
       handleChange,
       search,
	   doSearch,
       addCompetition,
	   deleteCompetition
    }
  }
})
