export function getNowDate() {
    const datetime = new Date()
    const year = datetime.getFullYear() //获取完整的年份(4位,1970)
    const month = datetime.getMonth() + 1//获取月份(0-11,0代表1月,用的时候记得加上1)
    const date = datetime.getDate();//获取日(1-31)
    let strMonth: number|string = month
    let strDate: number|string = date
    if(month <= 9){
        strMonth = "0" + month
    }

    if(date <= 9){
        strDate = "0" + date
    }
    
    const dateformat = year + "-" + strMonth + "-" + strDate;
    return dateformat;
}

export function getBeginDate(day: any) {
    const time = (new Date).getTime()-day*24*60*60*1000;
    const datetime = new Date(time)
    const year = datetime.getFullYear() //获取完整的年份(4位,1970)
    const month = datetime.getMonth() + 1//获取月份(0-11,0代表1月,用的时候记得加上1)
    const date = datetime.getDate();//获取日(1-31)
    let strMonth: number|string = month
    let strDate: number|string = date
    if(month <= 9){
        strMonth = "0" + month
    }

    if(date <= 9){
        strDate = "0" + date
    }
    
    const dateformat = year + "-" + strMonth + "-" + strDate;
    return dateformat;
}