<template>
    <div class="wrapper">
      <global-header :user="currentUser"></global-header>
      <div class="section">
        <router-view></router-view>
      </div>  
      <global-footer></global-footer>
    </div>
</template>

<script lang="ts">
import { defineComponent,computed } from 'vue';
import { useStore } from 'vuex'
import GlobalHeader from './components/GlobalHeader.vue'
import GlobalFooter from './components/GlobalFooter.vue'
import { GlobalDataProps } from './store'
export default defineComponent({
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  setup(){
    const store = useStore<GlobalDataProps>()
    const currentUser = computed(() => store.state.user)
    return {
      currentUser
    }
  }
});
</script>

<style>

html,body{
  height: 100%;
}

.wrapper{
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  min-height: 100vh;
}

.section{
  flex: 1;
  margin-top:45px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
