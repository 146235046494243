
import { defineComponent, reactive, onMounted, toRefs,ref } from 'vue'
import { SelectTypes } from 'ant-design-vue/es/select';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ResponseType } from '../store'
import { getNowDate } from '../helper'
import axios from 'axios'
const columns = [
  {
    title: '排名',
    dataIndex: 'ranking',
  },
  {
    title: '用户名',
    dataIndex: 'username',
  },
  {
    title: '速度',
    dataIndex: 'speed',
  },
  {
    title: '击键',
    dataIndex: 'keystrokes',
  },
  {
    title: '码长',
    dataIndex: 'ma_chang',
  },
  {
    title: '字数',
    dataIndex: 'word_number',
  },
  {
    title: '时间',
    dataIndex: 'typing_time',
  },
  {
    title: '回改',
    dataIndex: 'hui_gai',
  },
  {
    title: '回车',
    dataIndex: 'hui_che',
  },
  {
    title: '键数',
    dataIndex: 'jian_shu',
  },
  {
    title: '键准',
    dataIndex: 'jian_zhun',
  },
  {
    title: '重打',
    dataIndex: 'repeat_number',
  },
  {
    title: '打词',
    dataIndex: 'da_ci',
  },
  {
    title: '错字',
    dataIndex: 'wrong_number',
  },
  {
    title: '输入法',
    dataIndex: 'input_method',
  },
  {
    title: '设备',
    dataIndex: 'from',
  },
]

export interface GroupProps {
  group_number: string;
  group_name: string;
}

export default defineComponent({
  name: 'Home',
  components: {

  },
  setup() {
    const nowDate = getNowDate()
    const reactiveData = reactive({
      data: [],
      pagination: {
        pageSize:30,
        total:0,
        current:0,
      },
      loading: false,
      columns,
      defaultPeriod: nowDate,
      period: nowDate,
      groupNumber:'139711547',
      groupList:[] as any[],
      textInfo:{
        wordNumber:0,
        content:'',
      }
    })

    const store = useStore()

    const fetch = (params = {}) => {
      reactiveData.loading = true
      const payload = {
          groupNumber:reactiveData.groupNumber,
          period:reactiveData.period,
          ...params
      }

      store.dispatch('getGroupRankList', payload).then((data: ResponseType<any>) => {
          const result = data.data.data
          const pagination = { ...reactiveData.pagination };
          pagination.total = result.totalCount;
          reactiveData.loading = false;
          reactiveData.data = result.list;
          reactiveData.pagination = pagination;
      })
    }

    const getGroupCompetitionTextInfo = () => {
      const payload = {
          groupNumber:reactiveData.groupNumber,
          period:reactiveData.period,
      }

      store.dispatch('getGroupCompetitionTextInfo', payload).then((rawData: ResponseType<any>) => {
          const result = rawData.data
          if(result['status'] == 1){
              const data = result.data
              reactiveData.textInfo.content = data.content
              reactiveData.textInfo.wordNumber = data.word_number
          }
      })
    }

    const getGroupList = (params = {}) => {
      const payload = {}
      store.dispatch('getGroupList', payload).then((data) => {
          const result = data.data.data
          const groupLists: any = []
          result.forEach((val: any, idx: any, array: any) => {
              if(val.group_number > 1000){
                  groupLists.push({
                       value:val.group_number,
                       label:val.group_name,
                  })
              }
          })
          
          reactiveData.groupList = groupLists
      })
    }

    const search = () => {
      fetch({
        pageSize: reactiveData.pagination.pageSize,
        pos: 0,
      })

      getGroupCompetitionTextInfo()
    }

    onMounted(() => {

      getGroupList()
	  
      setTimeout(function(){
        search()
      },1000)
    })

    const handleTableChange = (pagination: any) => {
      const pager = { ...reactiveData.pagination }
      pager.current = pagination.current
      reactiveData.pagination = pager
      fetch({
        pageSize: pagination.pageSize,
        pos: (pagination.current - 1) * pagination.pageSize,
      })
    }

    const onPeriodChange = (date: any, dateString: any) => {
      reactiveData.period = dateString;
    }

    const focus = () => {
      console.log('focus')
    }
    
    const handleChange = (value: any) => {
      console.log(`selected ${value}`);
    }
  
    return {
       ...toRefs(reactiveData),
       handleTableChange,
       onPeriodChange,
       focus,
       handleChange,
       search
    }
  }
})
