
import { defineComponent, PropType, ref,watch } from 'vue'
import { HomeOutlined, AppstoreOutlined, CloudOutlined,UserOutlined,ChromeOutlined } from '@ant-design/icons-vue'
import { UserProps } from '../store'
import { useRouter,useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'GlobalHeader',
  components: {
    HomeOutlined,
    AppstoreOutlined,
    CloudOutlined,
    UserOutlined,
	ChromeOutlined
  },
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup(props, context){
    const router = useRouter()

    const clickMenu = ({key}: any) =>{
        if (key === '/cloud') {
            return window.open('http://www.52dazi.cn', '_blank');
        }else if (key === '/bbs') {
            return window.open('https://www.jsxiaoshi.com', '_blank');
        }else if(key === '/userData'){
            if(!props.user.isLogin){
              message.info("请先登录！")
              return
            }
        }else if(key === '/xjvip'){
          return window.open('http://www.jsxiaoshi.com/index.php/Home/Post/index/tid/2290','_blank')
        }else if(key === '/xjseniorversion'){
          return window.open('http://www.jsxiaoshi.com/index.php/Home/Post/index/tid/3388','_blank')
        }
        return router.push({ path: key })
    }

    return {
       clickMenu
    }
  },
});
