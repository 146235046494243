import { createStore, Commit } from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'
export interface ResponseType<P = {}> {
  status: number;
  msg: string;
  data: P;
}

export interface UserProps {
  isLogin: boolean;
  userId?: string;
  username?: string;
  avatar?: string;
}

export interface GlobalDataProps {
  token: string;
  user: UserProps;
}

const asyncAndCommit = async(url: string, mutationName: string,
  commit: Commit, config: AxiosRequestConfig = { method: 'get' }, extraData?: any) => {
  const { data } = await axios(url, config)
  if (extraData) {
    commit(mutationName, { data, extraData })
  } else {
    commit(mutationName, data)
  }
  return data
}
const store = createStore<GlobalDataProps>({
  state: {
    token: localStorage.getItem('token') || '',
    user: { 
      isLogin: localStorage.getItem('token') ?true :false,
      userId: localStorage.getItem('userId') || '',
      username: localStorage.getItem('username') || '',
      avatar: localStorage.getItem('avatar') || '',
    },
  },
  mutations: {
    login(state, rawData) {
      const status = rawData.status
      const result = rawData.data
      if(status == 1){
        const token = result.userToken
        const username = result.username
        const avatar = result.avatar
        const userId = result.userId

        state.token = token
        state.user = { 
          isLogin: true,
          userId: userId,
          username: username,
          avatar: avatar,
        }

        localStorage.setItem('token', token)
        localStorage.setItem('userId', userId)
        localStorage.setItem('username', username)
        localStorage.setItem('avatar', avatar)
        axios.defaults.headers.common["token"] = token
      }
    },
    logout(state) {
      state.token = ''
      state.user = { isLogin: false }
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('username')
      localStorage.removeItem('avatar')
      delete axios.defaults.headers.common["token"]
    }
  },
  actions: {
    getGroupList({ commit }, payload) {
      return axios.post('/group/getGroupList', payload)
    },
    getGroupRankList({ commit }, payload) {
      return axios.post('/rank/getGroupRankList', payload)
    },
    login({ commit }, payload) {
      return asyncAndCommit('/user/login', 'login', commit, { method: 'post', data: payload })
    },
    getJoinNumSummary({ commit }, payload) {
      return axios.post('/rank/getJoinNumSummary', payload)
    },
    getJoinNumRank({ commit }, payload) {
      return axios.post('/rank/getJoinNumRank', payload)
    },
    getWordNumberSummary({ commit }, payload) {
      return axios.post('/record/getWordNumberSummary', payload)
    },
    getWordNumberRank({ commit }, payload) {
      return axios.post('/record/getWordNumberRank', payload)
    },
    getUserRankSpeedList({ commit }, payload) {
      return axios.post('/rank/getUserRankSpeedList', payload)
    },
    getUserWordNumberRank({ commit }, payload) {
      return axios.post('/record/getUserWordNumberRank', payload)
    },
    getGroupCompetitionTextInfo({ commit }, payload) {
      return axios.post('/text/getGroupCompetitionTextInfo', payload)
    },
	getCompetitionTextList({ commit }, payload) {
	  return axios.post('/competitionText/getCompetitionTextList', payload)
	},
	addCompetitionText({ commit }, payload) {
	  return axios.post('/competitionText/addCompetitionText', payload)
	},
	editCompetitionText({ commit }, payload) {
	  return axios.post('/competitionText/editCompetitionText', payload)
	},
	getNextCompetitionBaseInfo({ commit },payload) {
	  return axios.post('/competitionText/getNextCompetitionBaseInfo', payload)
	},
	getCompetitionTextById({ commit },payload) {
	  return axios.post('/competitionText/getCompetitionTextById', payload)
	},
	getBattlePowerRank({ commit },payload) {
	  return axios.post('/rank/getBattlePowerRank', payload)
	},
	getGroupScoreRank({ commit },payload) {
	  return axios.post('/rank/getGroupScoreRank', payload)
	},
	getBattleNumRank({ commit },payload) {
	  return axios.post('/rank/getBattleNumRank', payload)
	},
	batchUploadText({ commit },payload) {
	  return axios.post('/competitionText/batchUploadText', payload)
	},
	deleteCompetitionText({ commit },payload) {
	  return axios.post('/competitionText/deleteCompetitionText', payload)
	},
  },
})

export default store
