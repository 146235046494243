
import { defineComponent, reactive, toRaw, UnwrapRef, ref, onMounted, watch } from "vue";
import { useRoute,useRouter } from "vue-router"
import { useStore } from "vuex";
import { message } from 'ant-design-vue'
const key = 'addCompetition';
interface FormState {
  textId: any;	
  textTitle: string;
  textContent: string;
  period: string;
  groupNumber: string | string[];
  groupName: string | string[];
  isBtnDisabled: boolean;
  isBatchUpload: boolean;
  replaceSpace: boolean;
  textPlaceholder: string;
  isSeniorVersion: number;
  userQQName: string;
}
export default defineComponent({
  setup() {
	const route = useRoute()
	const router = useRouter()
    const store = useStore()
	const textId = route.params.textId || 0
	const groupNumber = route.params.groupNumber || ''
	const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
	  textId:textId,		
      textTitle: "",
      textContent: "",
      period: "",
	  groupNumber: groupNumber,
	  groupName: "",
	  isBtnDisabled: false,
	  isBatchUpload: false,
	  replaceSpace: false,
	  textPlaceholder: '请输入赛文内容……',
	  isSeniorVersion: 0,
	  userQQName: ""
    })
	
	watch(()=> formState.isBatchUpload, (newVal, oldVal) => {
		if(newVal){
			formState.textPlaceholder = "批量上传无须填写期数，格式如下：\r\n标题1\r\n内容1\r\n\r\n标题2\r\n内容2\r\n……"
		}else{
			formState.textPlaceholder = '请输入赛文内容……'
		}
	})
		
	onMounted(() => {
		message.loading({'content':'加载中……',key})
		if(textId == 0){
			getNextCompetitionBaseInfo()
		}else{
			getCompetitionTextById()
		}
		
		if(formState.groupNumber != "726064238"){
			formState.replaceSpace = true
		}
	})
	
	const getNextCompetitionBaseInfo = () => {
		const payload = {
		  groupNumber:formState.groupNumber,
		  userToken:localStorage.getItem('token')
		}
		store.dispatch("getNextCompetitionBaseInfo", payload).then((data) => {
		  const result = data.data;
		  if(result.status == 1){
			formState.textTitle = result.data.title
			formState.period = result.data.competitionDate
			formState.isSeniorVersion = result.data.isSeniorVersion
			formState.groupName = result.data.groupName
		  }
		  message.success({ content: '加载完成！', key, duration: 2 });
		})
	}

	const getCompetitionTextById = () => {
		const payload = {
		  textId:textId,
		  userToken:localStorage.getItem('token')
		}
		store.dispatch("getCompetitionTextById", payload).then((data) => {
		  const result = data.data
		  if(result.status == 1){
			formState.textTitle = result.data.title
			formState.textContent = result.data.content
			formState.period = result.data.competition_date
			formState.groupName = result.data.group_name
			formState.userQQName = result.data.user_qq_name
		  }
		  message.success({ content: '加载完成！', key, duration: 2 });
		})
	}
	
    const onPeriodChange = (date: any, dateString: any) => {
      formState.period = dateString;
    }
	
	const onSubmit = () => {
		if(formState.textTitle === "" || formState.textContent === "" || formState.period === ""){
			message.info('信息不完善！')
			return
		}
		if(textId == 0){
			if(formState.isBatchUpload){
			  //判断是否支持该功能
			  if(formState.isSeniorVersion === 0){
				  message.info('批量上传功能为跟打群管理系统高级版专享，请先在会员中心开通！')
				  return
			  }
				
			  batchUploadText()
			}else{
			  addCompetitionText()
			}
		}else{
			editCompetitionText()
		}
	}
	
	const batchUploadText = () => {
		formState.isBtnDisabled = true
		const payload = {
		  content:formState.textContent,
		  groupNumber:formState.groupNumber,
		  userToken:localStorage.getItem('token'),
		  replaceSpace: formState.replaceSpace ? 1:0,
		}
		store.dispatch("batchUploadText", payload).then((data) => {
		  const result = data.data;
		  if(result.status == 1){
			formState.textContent = ''
		  }
		  message.info({ content: result.msg})
		  formState.isBtnDisabled = false
		})
	}

    const addCompetitionText = () => {
		formState.isBtnDisabled = true
		const payload = {
		  title:formState.textTitle,
		  content:formState.textContent,
		  beginTime:formState.period + "00:00:00",
		  endTime:formState.period + "23:59:59",
		  groupNumber:formState.groupNumber,
		  userToken:localStorage.getItem('token'),
		  replaceSpace: formState.replaceSpace ? 1:0,
		}
		store.dispatch("addCompetitionText", payload).then((data) => {
		  const result = data.data;
		  if(result.status == 1){
			formState.textContent = ''
			getNextCompetitionBaseInfo()
		  }
		  message.info({ content: result.msg})
		  formState.isBtnDisabled = false
		})
    }
	
	const editCompetitionText = () => {
		const payload = {
		  textId:textId,
		  title:formState.textTitle,
		  content:formState.textContent,
		  userToken:localStorage.getItem('token'),
		  replaceSpace: formState.replaceSpace ? 1:0,
		}
		store.dispatch("editCompetitionText", payload).then((data) => {
		  const result = data.data
		  message.info({ content: result.msg})
		  if(result.status == 1){
			router.push({
				name: 'competitionTextList',
				params: {
				    groupNumber:groupNumber,
				}
			})
		  }
		})
	}
	
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
	  formRef,
      formState,
      onPeriodChange,
      addCompetitionText,
	  onSubmit,
    }
  },
});
