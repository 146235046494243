
import { defineComponent, reactive, onMounted, toRefs, inject, ref } from 'vue'
import { useStore } from 'vuex'
import * as echarts from "echarts"
import { useRouter } from 'vue-router'
import { ResponseType } from '../store'
import { getNowDate,getBeginDate } from '../helper'
import axios from 'axios'
import { message } from 'ant-design-vue'
export interface GroupProps {
  group_number: string;
  group_name: string;
}
const key = 'updateGroupRank'
export default defineComponent({
  name: 'GroupRank',
  components: {

  },
  setup() {
    const beginDate = getBeginDate(7)
    const nowDate = getNowDate()

    const joinNumRankChart = ref<HTMLElement>()
    const showJoinNumRankChart = ref<any>()

    const wordNumberRankChart = ref<HTMLElement>()
    const showWordNumberRankChart = ref<any>()

    const userWordNumberRankChart = ref<HTMLElement>()
    const showUserWordNumberRankChart = ref<any>()
     
    const reactiveData = reactive({
      defaultBeginPeriod: beginDate,
      defaultEndPeriod: nowDate,
      beginPeriod: beginDate,
      endPeriod: nowDate,
      groupNumber:'',
      groupList:[] as any[],
    })

    const router = useRouter()

    const store = useStore()

    const getGroupList = (params = {}) => {
      const payload = {}
      store.dispatch('getGroupList', payload).then((data) => {
          const result = data.data.data
          const groupLists: any = [
            {
                'value':'',
                'label':'全部',
            }
          ];
          result.forEach((val: any, idx: any, array: any) => {
              if(val.group_number > 1000){
                  groupLists.push({
                       'value':val.group_number,
                       'label':val.group_name,
                  })
              }
          })
          
          reactiveData.groupList = groupLists
      })
    }

    const getJoinNumRank = () => {
      const payload = {
        beginTime:reactiveData.beginPeriod,
        endTime:reactiveData.endPeriod
      }
      
      store.dispatch('getJoinNumRank', payload).then((rawData) => {
          const result = rawData.data
          if(result.status == 1){
              const data = result.data
              const dataList = [['amount', 'product'],]
              const length = data.length

              if(typeof(length) == 'undefined'){
                  return
              } 
              for(let i = length-1;i>=0;i--){
                dataList.push([
                    data[i]['count'],data[i]['group_name']
                ])
              }


              const labelSetting = {
                  normal: {
                      show: true,
                      position: 'right',
                      offset: [10, 0],
                      textStyle: {
                          fontSize: 16
                      }
                  }
              }

              const maxData = 9999999

              showJoinNumRankChart.value = echarts.init(joinNumRankChart.value!);
              // 绘制图表
              showJoinNumRankChart.value.setOption(
                {
                  title: {
                      text: '群参赛人数排行',
                      left: 'center',
                  },
                  dataset: {
                      source: dataList
                  },
                  grid: {containLabel: true},
                  xAxis: {name: 'amount',splitLine: {show: false},axisLine: {show: false},axisTick: {show: false}, axisLabel: {show: false},},
                  yAxis: {type: 'category',axisTick: {show: false},
                      axisLine: {show: false},
                      axisLabel: {
                          margin: 10,
                          textStyle: {
                              color: '#999',
                              fontSize: 16
                          }
                      }},
              
                      series: [
                      {
                          type: 'pictorialBar',
                          label: labelSetting,
                          symbol: 'rect',
                          itemStyle: {
                          
                          normal: {
                              color: '#007eb1',
                              
                          }
                      },
                          encode: {
                              x: 'amount',
                              y: 'product'
                          },
                          z:10
                          
                      },
                      {
                      type: 'pictorialBar',
                      itemStyle: {
                          
                          normal: {
                              color: '#7dbad2',
                              opacity: 0.2
                          }
                      },
                      symbol: 'rect',
                      symbolBoundingData: maxData,
                      encode: {
                              x: 'amount',
                              y: 'product'
                          },
                      z: 5
                  }
                  ]
              }
              );
          }
      })
    }

    const getWordNumberRank = () => {
      const payload = {
        beginTime:reactiveData.beginPeriod,
        endTime:reactiveData.endPeriod
      }
      store.dispatch('getWordNumberRank', payload).then((rawData) => {
          const result = rawData.data
          if(result.status == 1){
              const data = result.data
              const dataList = [['amount', 'product'],]
              const length = data.length

              if(typeof(length) == 'undefined'){
                  return
              } 
              for(let i = length-1;i>=0;i--){
                dataList.push([
                    data[i]['word_number'],data[i]['group_name']
                ])
              }

              const labelSetting = {
                  normal: {
                      show: true,
                      position: 'right',
                      offset: [10, 0],
                      textStyle: {
                          fontSize: 16
                      }
                  }
              }

              const maxData = 9999999

              showWordNumberRankChart.value = echarts.init(wordNumberRankChart.value!);
              // 绘制图表
              showWordNumberRankChart.value.setOption(
                {
                  title: {
                      text: '群跟打字数排行',
                      left: 'center',
                  },
                  dataset: {
                      source: dataList
                  },
                  grid: {containLabel: true},
                  xAxis: {name: 'amount',splitLine: {show: false},axisLine: {show: false},axisTick: {show: false}, axisLabel: {show: false},},
                  yAxis: {type: 'category',axisTick: {show: false},
                      axisLine: {show: false},
                      axisLabel: {
                          margin: 10,
                          textStyle: {
                              color: '#999',
                              fontSize: 16
                          }
                      }},
              
                      series: [
                      {
                          type: 'pictorialBar',
                          label: labelSetting,
                          symbol: 'rect',
                          itemStyle: {
                          
                          normal: {
                              color: '#007eb1',
                              
                          }
                      },
                          encode: {
                              x: 'amount',
                              y: 'product'
                          },
                          z:10
                          
                      },
                      {
                      type: 'pictorialBar',
                      itemStyle: {
                          
                          normal: {
                              color: '#7dbad2',
                              opacity: 0.2
                          }
                      },
                      symbol: 'rect',
                      symbolBoundingData: maxData,
                      encode: {
                              x: 'amount',
                              y: 'product'
                          },
                      z: 5
                  }
                  ]
              }
              );
          }
      })
    }

    const getUserWordNumberRank = () => {
      const payload = {
        groupNumber:reactiveData.groupNumber,
        beginTime:reactiveData.beginPeriod,
        endTime:reactiveData.endPeriod
      }

      store.dispatch('getUserWordNumberRank', payload).then((rawData) => {
          const result = rawData.data
          if(result.status == 0){
             message.info({ content: result.msg, key, duration: 2 })
             return
          }
          message.success({ content: '加载完成!', key, duration: 2 })

          const data = result.data
          const dataList = [['amount', 'product'],]
          const length = data.length

          if(typeof(length) == 'undefined'){
              return
          } 
          for(let i = length-1;i >= 0;i--){
              dataList.push([
                  data[i]['word_number'],data[i]['user_qq_name']
              ])
          }
              
          const labelSetting = {
                  normal: {
                      show: true,
                      position: 'right',
                      offset: [10, 0],
                      textStyle: {
                          fontSize: 16
                      }
                  }
              }

          const maxData = 9999999

          showUserWordNumberRankChart.value = echarts.init(userWordNumberRankChart.value!);
          // 绘制图表
          showUserWordNumberRankChart.value.setOption(
                {
                  title: {
                      text: '用户跟打字数排行',
                      left: 'center',
                  },
                  dataset: {
                      source: dataList
                  },
                  grid: {containLabel: true},
                  xAxis: {name: 'amount',splitLine: {show: false},axisLine: {show: false},axisTick: {show: false}, axisLabel: {show: false},},
                  yAxis: {type: 'category',axisTick: {show: false},
                      axisLine: {show: false},
                      axisLabel: {
                          margin: 10,
                          textStyle: {
                              color: '#999',
                              fontSize: 16
                          }
                      }},
              
                      series: [
                      {
                          type: 'pictorialBar',
                          label: labelSetting,
                          symbol: 'rect',
                          itemStyle: {
                          
                          normal: {
                              color: '#007eb1',
                              
                          }
                      },
                          encode: {
                              x: 'amount',
                              y: 'product'
                          },
                          z:10
                          
                      },
                      {
                      type: 'pictorialBar',
                      itemStyle: {
                          
                          normal: {
                              color: '#7dbad2',
                              opacity: 0.2
                          }
                      },
                      symbol: 'rect',
                      symbolBoundingData: maxData,
                      encode: {
                              x: 'amount',
                              y: 'product'
                          },
                      z: 5
                  }
                  ]
              }
          )
      })
    }

    const search = () => {
      message.loading({ content: '加载中...', key })
      getJoinNumRank()
      getWordNumberRank()
      getUserWordNumberRank()
    }

    onMounted(() => {
      getGroupList() 
    
      setTimeout(function(){
        search()
      },1000)
    })

    const onBeginPeriodChange = (date: any, dateString: any) => {
      reactiveData.beginPeriod = dateString;
    }

    const onEndPeriodChange = (date: any, dateString: any) => {
      reactiveData.endPeriod = dateString;
    }
  
    return {
        ...toRefs(reactiveData),
        search,
        onBeginPeriodChange,
        onEndPeriodChange,
        joinNumRankChart,
        wordNumberRankChart,
        userWordNumberRankChart
    }
  }
})

