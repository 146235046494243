<template>
    <div class="footer">
        <a-row class="mb-8">
            <a-col :span="12">
            ©2021 打字练习 & 版权所有  
            </a-col>
            <a-col class="fs-12 ta-r" :span="12">
                <a-space size="large">
                    <a href="http://www.jsxiaoshi.com/index.php/Home/Cloud/index" target="_blank" rel="noopener noreferrer">云跟打</a>
                    <a href="http://doc.jsxiaoshi.com" target="_blank" rel="noopener noreferrer">使用教程</a>
                    <a href="http://www.jsxiaoshi.com/index.php/Home/Support/index" target="_blank">赞助我们</a>
					<a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=VCL0HrXPzxxaqEEa6Dus9tHSxKqV1siD&jump_from=webapi"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="小极粉丝群" title="加入小极粉丝群"></a>
                </a-space>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="fs-12" :span="12">
                <a-space>
                    <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备16016034号-2</a>    
                </a-space>
            </a-col>
            <a-col class="fs-12 ta-r" :span="12">
                打字练习，专业跟打群管理系统
            </a-col>
        </a-row>
    </div>
</template>

<style lang="scss" scoped>
$primary-color: #1890ff;

.footer {
    color: #fff;
    line-height: 22px;
    flex: 0;
    padding: 24px 0;
    background:rgba(0, 0, 0, 0.65);
    width:100%;

    a {
        color: #fff;
        &:hover {
            color: $primary-color;
        }
    }
}

.mb-8 {
    margin-bottom: 8px;
}

.fs-12 {
    font-size: 12px;
}

</style>
