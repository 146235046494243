
import { defineComponent, reactive, onMounted, toRefs,ref,watch } from 'vue'
import { SelectTypes } from 'ant-design-vue/es/select';
import { useStore } from 'vuex'
import { useRoute,useRouter } from 'vue-router'
import { ResponseType } from '../store'
import { getNowDate } from '../helper'
import axios from 'axios'
const columns = [
  {
    title: '排名',
    dataIndex: 'ranking',
  },
  {
    title: '段位',
    dataIndex: 'level_name',
  },
  {
    title: '用户',
    dataIndex: 'nickname',
  },
  {
    title: '战力',
    dataIndex: 'battle_power',
  },
  {
    title: '战绩',
    dataIndex: 'battle_num',
  },
  {
    title: '群积分',
    dataIndex: 'group_score',
  }
]

export interface GroupProps {
  group_number: string;
  group_name: string;
}

export default defineComponent({
  name: 'Home',
  components: {

  },
  setup() {
    const nowDate = getNowDate()
	const route = useRoute()
	let type = route.params.type || 1
    const reactiveData = reactive({
      data: [],
      pagination: {
        pageSize:30,
        total:0,
        current:0,
      },
      loading: false,
      columns,
      defaultPeriod: nowDate,
      period: nowDate
    })
	
	watch(route, () => {
		type =  route.params.type
		search()
	});

    const store = useStore()
    const fetch = (params = {}) => {
      reactiveData.loading = true
      const payload = {
          ...params
      }
	  const url = 'getGroupScoreRank'
      store.dispatch(url, payload).then((data: ResponseType<any>) => {
          const result = data.data.data
          const pagination = { ...reactiveData.pagination };
          pagination.total = result.totalCount;
          reactiveData.loading = false;
          reactiveData.data = result.list;
          reactiveData.pagination = pagination;
      })
    }

    const search = () => {
      fetch({
        pageSize: reactiveData.pagination.pageSize,
        pos: 0,
      })
    }

    onMounted(() => {
      setTimeout(function(){
        search()
      },1000)
    })

    const handleTableChange = (pagination: any) => {
      const pager = { ...reactiveData.pagination }
      pager.current = pagination.current
      reactiveData.pagination = pager
      fetch({
        pageSize: pagination.pageSize,
        pos: (pagination.current - 1) * pagination.pageSize,
      })
    }

    const onPeriodChange = (date: any, dateString: any) => {
      reactiveData.period = dateString;
    }

    const focus = () => {
      console.log('focus')
    }
    
    const handleChange = (value: any) => {
      console.log(`selected ${value}`);
    }
  
    return {
       ...toRefs(reactiveData),
       handleTableChange,
       onPeriodChange,
       focus,
       handleChange,
       search,
	   type
    }
  }
})
