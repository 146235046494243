<template>
  <div class="header">
    <a-menu :selectedKeys="[$route.path]" mode="horizontal" @click="clickMenu">
      <a-menu-item key="/">
		  <template #icon><home-outlined /></template>
		  <router-link to="/">赛文榜</router-link>
	  </a-menu-item>
	  <a-sub-menu>
	  	<template #icon><appstore-outlined /></template>
	    <template #title>
	      <span class="submenu-title-wrapper">
	         国服排行 
	      </span>
	    </template>
	    <a-menu-item key="/battlePowerRank">
	      国服战力榜
	    </a-menu-item>
	    <a-menu-item key="/battleNumRank">
	      国服战绩榜
	    </a-menu-item>
	    <a-menu-item key="/groupScoreRank">
	      国服积分榜
	    </a-menu-item>
	  </a-sub-menu>
      <a-sub-menu>
		<template #icon><appstore-outlined /></template>
        <template #title>
          <span class="submenu-title-wrapper">
             数据中心 
          </span>
        </template>
        <a-menu-item key="/groupSummary">
          群汇总
        </a-menu-item>
        <a-menu-item key="/groupRank">
          群排行
        </a-menu-item>
        <a-menu-item key="/userData">
          个人数据
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="/cloud">
		  <template #icon><cloud-outlined /></template>
          云跟打
      </a-menu-item>
	  <a-menu-item key="/bbs">
	  	  <template #icon><chrome-outlined /></template>
	      打字圈
	  </a-menu-item>
      <a-menu-item key="/login" v-if="!user.isLogin">
          登录
      </a-menu-item>

      <a-sub-menu v-else>
		<template #icon><user-outlined /></template>
        <template #title>
          <span class="submenu-title-wrapper">
            欢迎您！{{user.username}}
          </span>
        </template>
        <a-menu-item key="/xjvip">
            开通小极专享服务 
        </a-menu-item>
		<a-menu-item key="/xjseniorversion">
		    开通跟打群管理系统高级版
		</a-menu-item>
		<a-menu-item key="/competitionTextList">
		    赛文管理
		</a-menu-item>
        <a-menu-item key="/logout">
            退出
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
	<img v-if="user.isLogin" :src="user.avatar" class="user-avatar">
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref,watch } from 'vue'
import { HomeOutlined, AppstoreOutlined, CloudOutlined,UserOutlined,ChromeOutlined } from '@ant-design/icons-vue'
import { UserProps } from '../store'
import { useRouter,useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'GlobalHeader',
  components: {
    HomeOutlined,
    AppstoreOutlined,
    CloudOutlined,
    UserOutlined,
	ChromeOutlined
  },
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup(props, context){
    const router = useRouter()

    const clickMenu = ({key}: any) =>{
        if (key === '/cloud') {
            return window.open('http://www.52dazi.cn', '_blank');
        }else if (key === '/bbs') {
            return window.open('https://www.jsxiaoshi.com', '_blank');
        }else if(key === '/userData'){
            if(!props.user.isLogin){
              message.info("请先登录！")
              return
            }
        }else if(key === '/xjvip'){
          return window.open('http://www.jsxiaoshi.com/index.php/Home/Post/index/tid/2290','_blank')
        }else if(key === '/xjseniorversion'){
          return window.open('http://www.jsxiaoshi.com/index.php/Home/Post/index/tid/3388','_blank')
        }
        return router.push({ path: key })
    }

    return {
       clickMenu
    }
  },
});
</script>
<style lang="scss" scoped>
  .header{
      flex: 0;
	  margin-left:28%;
  }
  
  .user-avatar{
	  position:relative;
	  width:50px;
	  height:50px;
	  border-radius: 50px;
	  top:-48px;
	  float:right;
	  right:100px;
  }
</style>