
import { defineComponent, reactive, onMounted, toRefs, inject, ref } from 'vue'
import { useStore } from 'vuex'
import * as echarts from "echarts"
import { useRouter } from 'vue-router'
import { ResponseType } from '../store'
import { getNowDate,getBeginDate } from '../helper'
import axios from 'axios'
import { message } from 'ant-design-vue'
export interface GroupProps {
  group_number: string;
  group_name: string;
}
const key = 'updateUserData'
export default defineComponent({
  name: 'UserData',
  components: {

  },
  setup() {
    const beginDate = getBeginDate(7)
    const nowDate = getNowDate()

    const joinNumSummaryChart = ref<HTMLElement>()
    const showJoinNumSummaryChart = ref<any>()

    const wordNumberSummaryChart = ref<HTMLElement>()
    const showWordNumberSummaryChart = ref<any>()
     
    const reactiveData = reactive({
      data: [],
      pagination: {
        pageSize:30,
        total:0,
        current:0,
      },
      loading: false,
      defaultBeginPeriod: beginDate,
      defaultEndPeriod: nowDate,
      beginPeriod: beginDate,
      endPeriod: nowDate,
      groupNumber:'41639633',
     groupList:[] as any[],
    })

    const router = useRouter()

    const store = useStore()

    const getGroupList = (params = {}) => {
      const payload = {}
      store.dispatch('getGroupList', payload).then((data) => {
          const result = data.data.data
          const groupLists: any = [
            {
                'value':'',
                'label':'全部',
            }
          ];
          result.forEach((val: any, idx: any, array: any) => {
              if(val.group_number > 1000){
                 groupLists.push({
                      'value':val.group_number,
                      'label':val.group_name,
                 })
              }
          })
          
          reactiveData.groupList = groupLists
      })
    }

    const getUserRankSpeedList = () => {
      const payload = {
        groupNumber:reactiveData.groupNumber,
        beginTime:reactiveData.beginPeriod,
        endTime:reactiveData.endPeriod,
      }
      
      store.dispatch('getUserRankSpeedList', payload).then((res) => {
          const result = res.data
          const data = result.data
          if(result.status == 1){
              const dateList = [];
              const countList = [];
              for(const i in data){
                  dateList.push(data[i]['date'])
                  countList.push(data[i]['speed'])
              }

              if(dateList.length == 0){
                message.info("暂无赛文速度数据")
                return
              }

              showJoinNumSummaryChart.value = echarts.init(joinNumSummaryChart.value!);
              // 绘制图表
              showJoinNumSummaryChart.value.setOption({
                  title: { 
                    text: "赛文速度",
                    left:'center'
                  },
                  tooltip: {},
                  xAxis: {
                    data: dateList,
                  },
                  yAxis: {},
                  series: [
                    {
                      name: "赛文速度",
                      type: "line",
                      data: countList,
                    },
                  ],
              });
          }
      })
    }

     const getWordNumberSummary = () => {
      const payload = {
        groupNumber:reactiveData.groupNumber,
        beginTime:reactiveData.beginPeriod,
        endTime:reactiveData.endPeriod,
        searchType:1,
      }
      store.dispatch('getWordNumberSummary', payload).then((data) => {
          const result = data.data
           if(result.status == 0){
             message.info({ content: result.msg, key, duration: 2 })
             return
          }
          message.success({ content: '加载完成!', key, duration: 2 })

          const mydata = result.data
          const dateList = [];
          const wordNumberList = [];
          for(const i in mydata){
              dateList.push(mydata[i]['date'])
              wordNumberList.push(mydata[i]['word_number'])
          }

          if(dateList.length == 0){
             message.info("暂无个人跟打字数数据")
             return
          }

          showWordNumberSummaryChart.value = echarts.init(wordNumberSummaryChart.value!)
          showWordNumberSummaryChart.value.setOption({
                  title: { 
                    text: "个人跟打字数",
                    left:'center'
                  },
                  tooltip: {},
                  xAxis: {
                    data: dateList,
                  },
                  yAxis: {},
                  series: [
                    {
                      name: "个人跟打字数",
                      type: "line",
                      data: wordNumberList,
                    },
                  ],
           });
      })
    }

    const search = () => {
      message.loading({ content: '加载中...', key })
      getUserRankSpeedList()
      getWordNumberSummary()
    }

    onMounted(() => {
      getGroupList()

      setTimeout(function(){
        search()
      },1000)
    })

    const onBeginPeriodChange = (date: any, dateString: any) => {
      reactiveData.beginPeriod = dateString;
    }

    const onEndPeriodChange = (date: any, dateString: any) => {
      reactiveData.endPeriod = dateString;
    }
  
    return {
        ...toRefs(reactiveData),
        search,
        onBeginPeriodChange,
        onEndPeriodChange,
        joinNumSummaryChart,
        wordNumberSummaryChart
    }
  }
})

